.navbar-brand {
    display: inline-block;
    color: #fff;
    font-weight: bold !important;
    font-family: "Fira Sans", sans-serif !important;
    font-size: 1.5rem;
    line-height: inherit;
    white-space: nowrap;
}

.div-circular-progress-bar {
    
}

.circular-progress-bar{
    height: 55px;
}

.circular-progress-bar-center{
    margin-left: 29.7rem;
}

@media (max-width: 1399px) {
    .circular-progress-bar-center{
        margin-left: 23.9rem;
    }
}

@media (max-width: 1199px) {
    .circular-progress-bar-center{
        margin-left: 13.7rem;
    }
}

@media (max-width: 991px) {
    .circular-progress-bar-center{
        margin-left: 8.7rem;
    }
}

@media (max-width: 767px) {
    .circular-progress-bar-center{
        margin-left: 3.7rem;
    }
}

@media (max-width: 575px) {
    .circular-progress-bar-center{
        margin-left:0;
    }
}

 