.grid-container {
    display: grid;
    grid-template-columns: 150px 150px 150px 150px;
}

.grid-card {
    position: relative;
}

.grid-card::after {
    content: "";
    background: url("card_back_1.png") no-repeat;
    object-fit: contain;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(0) perspective(100px);
    transition: 0.4s;
    backface-visibility: hidden;
}

.grid-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    object-position: center;
    transform: rotateY(180deg) perspective(100px);
    transition: 0.4s;
    backface-visibility: hidden;
    border: none;
}

.grid-card-show::after {
    transform: rotateY(180deg) perspective(100px);
}

.grid-card-show .grid-img {
    transform: rotateY(0) perspective(100px);
}

.grid-card-finished {
    opacity: 0.0;
    transition: opacity 0.4s;
}

.btn.btn-primary:disabled {
    background-color: #808080;
}

.card-image-memory {
    width: 100%;
    height: 15rem;
    object-fit: contain;
}

.image-style-memory {
    width: 100%;
    height: 20vw;
    object-fit: contain;
    overflow: hidden;
    object-position: center;
}


.title-style {
    font-size: 2rem;
    color: #4473AF;
}

.introduction-text-style {
    font-size: 1.6rem;
}

.memory-link-div-style {
    margin-top: 1.8rem;
    margin-right: 4.0rem;
}

.hr-style {
    display: inline-block;
    border: 2px solid #4473AF;
    border-radius: 5px;
    margin-top: 4rem;
    width: calc((205% - 1.5em) / 2);
}

.disabled-link {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.2;
    text-decoration: none
}

@media screen and  (max-width: 576px) {
    .title-style {
        font-size: 1.4rem;
        color: #4473AF;
    }

    .introduction-text-style {
        font-size: 1.0rem;
    }

    .memory-link-div-style {
        margin-top: 0.1rem;
        margin-right: 1.8rem;
    }

    .grid-container {
        display: grid;
        grid-template-columns: 80px 80px 80px 80px;
    }

}

@media screen and (min-width: 577px) and (max-width: 721px) {
    .grid-container {
        display: grid;
        grid-template-columns: 130px 130px 130px 130px;
    }
}


/*@media screen and (min-width: 722px) and (max-width: 875px) {*/
/*    .grid-container-easy {*/
/*        display: grid;*/
/*        grid-template-columns : 150px 150px 150px 150px;*/
/*    }*/
/*}*/

/*@media screen and (min-width: 768px) and (max-width: 875px) {*/
/*    .grid-container-easy {*/
/*        display: grid;*/
/*        grid-template-columns : 170px 170px 170px 170px;*/
/*    }*/
/*}*/

/*@media screen and (min-width: 876px) and (max-width: 1200px){*/
/*    .grid-container-easy {*/
/*        display: grid;*/
/*        grid-template-columns : 170px 170px 170px 170px;*/
/*    }*/
/*}*/
