.card-image-top {
    width: 100%;
    height: 10rem;
    object-fit: contain;
}

.card-style {
    border: none;
}

.font-style {
    font-weight: bold;
    font-size: 2rem;
    color: #4473AF;
}

.link-style {
    text-decoration: none;
    color: black
}

.disabled-link {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.2;
    text-decoration: none
}


.introduction-text-style {
    font-size: 1.8rem;
}

.link-div-style {
    margin-top: 1.8rem;
    margin-right: 4.0rem;
}

.div-center {
    margin: auto;
    width: 40%;
    padding-left: 5vw;
    font-size: 18px;
    font-weight: bold;
}

.message-div {
    margin-left: 1vw;
    margin-top: -0.25vw;
}

.center-row-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
}

.margin-top{
    margin-top: 8vw;
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    .div-center {
        margin: auto;
        width: 40%;
        font-size: 16px;
        font-weight: bold;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .div-center {
        margin: auto;
        width: 50%;
        font-size: 12px;
        font-weight: bold;
    }
}

@media screen and (min-width: 578px) and (max-width: 767px){
    .div-center {
        margin: auto;
        width: 65%;
        font-size: 12px;
        font-weight: bold;
    }
}

@media screen and (max-width: 577px) {
    .introduction-text-style {
        font-size: 1.2rem;
    }

    .font-style {
        font-weight: bold;
        font-size: 1.2rem;
        color: #4473AF;
    }

    .card-image-top {
        width: 100%;
        height: 5rem;
        object-fit: contain;
    }

    .link-div-style {
        margin-top: 1.0rem;
        margin-right: 1.8rem;
    }

    .div-center {
        margin: auto;
        width: 70%;
        font-size: 13px;
        font-weight: bold;
    }
    .margin-top{
        margin-top: 0vw;
    }
    
}

