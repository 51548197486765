.box {
    background: #fff;
    border-radius: 2px;
    padding-bottom: 50%;
}


.box-style-xs {
    width: 3rem;
    height: 4rem;
    margin: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    background-color: #e3e2e1;
}

.text-style-xs {
    font-size: 1.0rem;
}

.box-style-2 {
    width: 4rem;
    height: 4rem;
    color: black;
    font-size: 2rem;
    text-align: center;
}

/*.card-image-top {*/
/*    width: 100%;*/
/*    height: 10rem;*/
/*    object-fit: contain;*/
/*}*/

.disabled-link {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.2;
    text-decoration: none
}


.title-style {
    font-size: 2rem;
    color: #4473AF;
}

.introduction-text-style {
    font-size: 1.6rem;
    justify-content: space-evenly;
}

.font-size-style {
    font-size: 1.2rem;
    color: black;
}

.card-image-update-items {
    width: 100%;
    height: 13rem;
    object-fit: contain;
}

.hr-style {
    display: inline-block;
    border: 2px solid #4473AF;
    border-radius: 5px;
    margin-top: 1rem;
    width: calc((205% - 1.5em) / 2);
}

.button-ok {
    display: inline-block;
    padding: 15px 25px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 15px;
}

.button-ok:hover {
    background-color: #3e8e41
}

.button-ok:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.button-ok-disable {
    display: inline-block;
    padding: 10px 20px;
    font-size: 24px;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #000000;
    background-color: #999999;
    border: none;
    border-radius: 15px;
}

.button-clear {
    display: inline-block;
    padding: 10px 20px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #F9350B;
    border: none;
    border-radius: 15px;
}

.button-clear:hover {
    background-color: #D53A18
}

.button-clear:active {
    background-color: #D53A18;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}


@media (max-width: 576px) {
    .box-style-2 {
        width: 4rem;
        height: 4rem;
        color: black;
        font-size: 1.5rem;
        text-align: center;
    }

    .title-style {
        font-size: 1.4rem;
        color: #4473AF;
    }

    .introduction-text-style {
        font-size: 1.0rem;
    }

    .font-size-style {
        font-size: 0.8rem;
    }

    .memory-link-div-style {
        margin-top: 0.1rem;
        margin-right: 1.8rem;
    }
}


@media (min-width: 768px) {
    .box-style-sm {
        width: 6rem;
        height: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        background-color: #e3e2e1;
    }

    .text-style-sm {
        font-size: 1.7rem;
    }
}

@media (min-width: 992px) {
    .box-style-md {
        width: 8rem;
        height: 9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        background-color: #e3e2e1;
    }

    .text-style-md {
        font-size: 1.9rem;
    }
}

@media (min-width: 1200px) {
    .box-style-lg {
        width: 10rem;
        height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        background-color: #e3e2e1;
    }

    .text-style-lg {
        font-size: 2rem;
    }
}


.color-box {
    width: 22rem;
    height: 22rem;
}

.button {
    background-color: #04AA6D; /* Green */
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.circle-button {
    border-radius: 50%;
}

.disabled-button {
    pointer-events: none;
    opacity: 0.4;
}

.button-style {
    text-decoration: none;
}
