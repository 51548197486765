.light-grid-row {
  vertical-align: middle;
  min-width: 60px !important;
  max-width: 600px;
}

.light-grid-row-3x3 {
  height: 33.33%;
}

.light-grid-row-4x4 {
  height: 25%;
}

.light-circle {
  min-width: 20px !important;
  max-width: 200px;
  display: inline-block;
  vertical-align: middle;
  margin: 1%;
  background-color: white;
  border-radius: 50%;
  border: 1px solid black;
  justify-content: center;
  text-align: bottom;
  padding-bottom: 100%;
}

.light-circle-disabled {
  min-width: 20px !important;
  max-width: 200px;
  display: inline-block;
  vertical-align: middle;
  margin: 1%;
  background-color: white;
  border-radius: 50%;
  border: 1px solid black;
  justify-content: center;
  text-align: bottom;
  padding-bottom: 100%;
}

.light-circle-3x3 {
  width: 31.33%;
  padding-bottom: 31.33%;
}

.light-circle-4x4 {
  width: 22.33%;
  padding-bottom: 23.33%;
}

.light-circle-selected {
  background-color: red !important;
}

.light-circle-selected-correct {
  background-color: lime !important;
}

.light-circle:active {
  background-color: red !important;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

/*.active {*/
/*    background-color: red !important;*/
/*    box-shadow: 0 5px #666;*/
/*    transform: translateY(4px);*/
/*}*/

.text-size {
  font-size: 1.6rem;
}

@media screen and (min-width: 1400px) {
  .light-grid-center {
    margin-left: 21rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .light-grid-center {
    margin-left: 15rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .light-grid-center {
    margin-left: 9rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .light-grid-center {
    margin-left: 2.9rem;
  }
}

@media screen and (max-width: 767px) {
  .text-size {
    font-size: 1.1rem;
  }
}
