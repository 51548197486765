.circular-progress-bar-loading{
    width: 100px;
    height: 100px;
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*margin: -50px 0 0 -50px;*/
}

.CircularProgressbar-path {
    stroke: red;
}
.CircularProgressbar-trail {
    stroke: white;
}
.CircularProgressbar-text {
    stroke: white;
    padding: 10px;
    fill: #3e98c7;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
}

.div-style-loading {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circular-style {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -50px;
}