.stats-circular-progress-bar{
    height: 200px;
}

.font-style-1 {
    font-size: 1.8rem;
}

.font-style-2 {
    font-size: 1.0rem;
}

.div-style-chart {
    margin: auto; 
    width: 50%;
}

@media screen and (min-width: 577px) and (max-width: 991px){
    .div-style-chart {
        margin: auto;
        width: 70%;
    }
}

@media screen and (max-width: 576px) {
    .stats-circular-progress-bar{
        height: 100px;
    }
    .font-style-1 {
        font-size: 1rem;
    }
    .div-style-chart {
        margin: auto;
        width: 100%;
    }
    .font-style-2 {
        font-size: 0.8rem;
    }
}