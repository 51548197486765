.circle-div {
    width: 200px;
    height: 200px;
    line-height: 180px;
    border-radius: 50%;
    border: 8px solid #4473AF;
    font-size: 70px;
    font-weight: bolder;
    color: #000;
    text-align: center;
    background: #fff
}