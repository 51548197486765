.card-image-top {
    width: 100%;
    height: 10rem;
    object-fit: contain;
}

.introduction-text-style {
    font-size: 1.6rem;
}

.font-size-style {
    font-size: 1.2rem;
    color: black;
}

.title-style {
    /*font-size: 2.5rem;*/
    /*color: #4473AF;*/
    font-weight: bold;
    font-size: 2rem;
    color: #4473AF;
}

.shadowed-text-for-yellow {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.triplets-box-1 {
    width: 20rem;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    font-weight: bolder;
    background-color: #ffffff;
    margin-top: 6rem;
    margin-right: 4rem;
    margin-left: 2rem;
}

.triplets-box-1-long-word {
    width: 20rem;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.8rem;
    font-weight: bolder;
    background-color: #ffffff;
    margin-top: 6rem;
    margin-right: 4rem;
    margin-left: 4.0rem;
}

.triplets-box-2 {
    display: inline-block;
    width: 35rem;
    height: 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3.0rem;
    font-weight: bolder;
    background-color: #ffffff;
    border: 1rem solid;
}

.triplets-button-style {
    width: 10rem;
    height: 8rem;
    font-size: 1.0rem;
    border: 1px solid #4473AF
}

.triplets-button-style-black-text {
    width: 10rem;
    height: 8rem;
    font-size: 1.0rem;
    color: black !important;
}


.triplet-link-div-style {
    margin-top: 1.8rem;
    margin-right: 4.0rem;
}

.hr-style {
    display: inline-block;
    border: 2px solid #4473AF;
    border-radius: 5px;
    margin-top: 4rem;
    width: calc((205% - 1.5em) / 2);
}

.disabled-link {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.2;
    text-decoration: none
}

.triplets-text-style {
    margin-top: 2rem;
    padding-left: 2rem;
    padding-right: 3rem;
}


@media (max-width: 576px) {
    .card-image-top {
        width: 100%;
        height: 5rem;
        object-fit: contain;
    }

    .introduction-text-style {
        font-size: 1.0rem;
    }

    .font-size-style {
        font-size: 0.8rem;
    }

    .title-style {
        font-size: 1.4rem;
        color: #4473AF;
    }

    .triplets-style-xs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    .triplets-box-1 {
        width: 20rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.0rem;
        font-weight: bolder;
        background-color: #ffffff;
        margin-top: 1rem;
        margin-bottom: 2rem;
        margin-left: 4rem;
        position: relative;
    }

    .triplets-box-1-long-word {
        width: 20rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.7rem;
        font-weight: bolder;
        background-color: #ffffff;
        margin-top: 1rem;
        margin-bottom: 2rem;
        margin-left: 4rem;
        position: relative;
    }

    .triplets-box-2 {
        display: inline-block;
        width: 20rem;
        height: 20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        font-weight: bolder;
        background-color: #ffffff;
        border: 1rem solid;
    }

    .triplets-text-style {
        font-size: 1.0rem;
        margin-top: 2rem;
        padding-left: 4rem;
        padding-right: 3rem;
    }

    .triplets-button-style {
        width: 7rem;
        height: 5rem;
        font-size: 0.65rem;
        border: 1px solid #4473AF
    }

    .triplets-button-style-black-text {
        width: 7rem;
        height: 5rem;
        font-size: 0.65rem;
        color: black !important;
    }

    .triplets-question-button {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .triplet-link-div-style {
        margin-top: 0.1rem;
        margin-right: 1.8rem;
    }


}

@media only screen and (min-width: 577px) and (max-width: 767px) {
    .triplets-style-xs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
    }

    .triplets-box-1 {
        width: 10rem;
        height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        font-weight: bolder;
        background-color: #ffffff;
        margin-top: 6rem;
        margin-left: 4rem;
    }

    .triplets-box-1-long-word {
        width: 10rem;
        height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.0rem;
        font-weight: bolder;
        background-color: #ffffff;
        margin-top: 6rem;
        margin-left: 4rem;
    }

    .triplets-text-style {
        font-size: 1.4rem;
        padding: 1rem;
        margin-left: -1.4rem;
    }

    .triplets-button-style {
        width: 7rem;
        height: 5rem;
        font-size: 0.67rem;
        border: 1px solid #4473AF
    }


    .triplets-button-style-black-text {
        width: 7rem;
        height: 5rem;
        font-size: 0.67rem;
        color: black !important;
    }
}


@media (min-width: 768px) {
    .triplets-style-sm {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

@media (min-width: 992px) {
    .triplets-style-md {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

@media (min-width: 1200px) {
    .triplets-style-lg {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}
