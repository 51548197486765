.card-image-top {
    width: 100%;
    height: 10rem;
    object-fit: contain;
}

.card-image-top-1 {
    height: 4rem;
}


.title-style {
    font-size: 2rem;
    color: #4473AF;
}

.introduction-text-style {
    font-size: 1.6rem;
}

.font-size-style {
    font-size: 1.2rem;
    color: black;
}

.card-image-shape {
    width: 100%;
    height: 10rem;
    object-fit: contain;
}

.div-style-shape {
    width: 30rem;
    height: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.div-button {
    display: inline-block;
    padding: 15px 25px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #FFFFFF;
    border: none;
    border-radius: 15px;
}

.div-button:hover {
    background-color: #f5f4f2
}

.div-button:active {
    background-color: #FFFFFF;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.disabled-link {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.2;
    text-decoration: none
}



.image-style-shape {
    width: 100%;
    height: 20vw;
    object-fit: contain;
    overflow: hidden;
    object-position: center;
}

.img-style {
    width: 10rem;
    height: 10rem;
}

.text-style {
    font-size: 1.5rem;
    font-weight: bolder;
}

.even-odd-div-color {
    background-color: lightpink;
}

.mental-link-div-style {
    margin-top: 1.8rem;
    margin-right: 4.0rem;
}

.hr-style {
    display: inline-block;
    border: 2px solid #4473AF;
    border-radius: 5px;
    margin-top: 4rem;
    width: calc((205% - 1.5em) / 2);
}

@media screen and (max-width: 576px) {
    .card-image-top {
        width: 100%;
        height: 5rem;
        object-fit: contain;
    }

    .card-image-top-1 {
        height: 2rem;
    }

    .introduction-text-style {
        font-size: 1.0rem;
    }

    .font-size-style {
        font-size: 0.8rem;
    }

    .title-style {
        font-size: 1.4rem;
        color: #4473AF;
    }

    .div-style-shape {
        width: 22rem;
        height: 22rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 40px;
    }

    .div-style-2 {
        width: 10rem;
        height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 7rem;
        font-weight: bolder;
    }

    .img-style {
        width: 8rem;
        height: 8rem;
    }

    .text-style {
        font-size: 1.0rem;
        margin-left: 1rem;
        margin-right: 1rem;
        font-weight: bolder;
    }

    .mental-link-div-style {
        margin-top: 0.1rem;
        margin-right: 1.8rem;
    }
}
